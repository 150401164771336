body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-underline-offset: 2px;
}

input {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
